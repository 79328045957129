<template>
  <div>
    <div class="footer">
      <div class="main">
        <div class="footerMain">
          <div class="left">
            <ul>
              <li>平台咨询</li>
              <li>商务合作</li>
              <li>加入我们</li>
              <li>售后支持</li>
              <li @click="goAbout">关于我们</li>
            </ul>
          </div>
          <div class="center">
            <div class="centerMain">
              <div>服务与支持</div>
              <div>客服热线 400-860-8020</div>
              <div>工作时间 9:00 — 18:00(工作日)</div>
            </div>
          </div>
          <div class="right">
            <div class="rightMain">
              <div class="qrCode">
                <img src="../../assets/main/qrCode.png" alt="" />
              </div>
              <div class="words">扫描二维码关注我们</div>
            </div>
          </div>
        </div>
        <div class="copy">
          <a href="https://beian.miit.gov.cn" target="_blank"
            >Copyright&copy; 2025 GoldMatrix.All Rights
            Reserved.新ICP备2024009600号-2</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    goAbout() {
      this.$router.push("/about");
      this.$emit("goTop");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 400px;
  background: rgba(37, 37, 37, 1) url("../../assets/main/footerBg.webp")
    no-repeat;
  border-top: 1px solid transparent;
  .main {
    width: 1200px;
    height: 300px;
    margin: 0 auto;
    margin-top: 100px;
    .footerMain {
      display: flex;
      justify-content: space-between;
      .left {
        width: 278px;
        height: 188px;
        border-right: 1px solid rgba(251, 251, 251, 1);
        ul {
          height: 100%;
          li {
            width: 56px;
            height: 14px;
            font-size: 14px;
            color: rgba(226, 228, 234, 1);
            margin-top: 20px;
            cursor: pointer;
          }
          li:nth-child(1) {
            margin-bottom: 40px;
            margin-top: 0;
            width: 64px;
            height: 16px;
            font-size: 16px;
          }
          li:hover {
            color: orange;
          }
        }
      }
      .center {
        width: 599px;
        height: 188px;
        border-right: 1px solid rgba(251, 251, 251, 1);
        .centerMain {
          margin-left: 181px;
          div {
            color: rgba(251, 251, 251, 1);
            font-size: 16px;
            margin-top: 20px;
          }
          div:nth-child(1) {
            color: rgba(226, 228, 234, 1);
            margin-bottom: 40px;
          }
        }
      }
      .right {
        width: 324px;
        height: 188px;
        .rightMain {
          float: right;
          .qrCode {
            width: 144px;
            height: 144px;
            margin-top: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .words {
            width: 144px;
            height: 16px;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
          }
        }
      }
    }
    .copy {
      color: #999999;
      text-align: left;
      height: 100px;
      line-height: 100px;
      font-size: 12px;
    }
  }
}
</style>
